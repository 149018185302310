import { useEffect, useState } from "react";
import { getUserDailyReminders, setAsTriggered } from "../../api/axios";
import { timeDiffFromNowInSeconds } from "../tools/datetime";
import { addNotification } from "../tools/notifications";
import { useVisibility } from "../ui/useVisibility";

/*
    IMPORTANT: rappels de la veille n'intéragissent jamais avec l'application

    CONTEXT: dans popup.js et notification.js se tiens le code pour demander l'autorisation pour les notifications

    an active reminder is a reminder whose trigger datetime already has passed

    features:
        * for active daily events: display them over their lead uI
        * for active dailyt events: notification in web browser
        * for active dailyt events: popup inside all tabs
*/


export function useReminders({ auth }) {
    const [reminders, setReminders] = useState(null); // contains only active reminders

    const isVisible = useVisibility();

    useEffect(() => {
        if (isVisible) {
            reloadReminders(auth);
        }
    }, [auth, isVisible]);

    const markReminderLocallyAsViewed = (reminderId) => {
        console.log("markReminderLocallyAsViewed reminderId", reminderId);
        setReminders(prevReminders => {
            if (prevReminders === null) {
                return null;
            }

            const newReminders = [...prevReminders];
            return newReminders.map(rem => {
                if (rem.id === reminderId) {
                    return { ...rem, marked_as_viewed: true };
                } else {
                    return rem;
                }
            })
        });
    }

    const addReminder = (newReminder) => {
        setReminders(prevReminders => {
            if (prevReminders === null) {
                return [newReminder];
            } else {
                return [...prevReminders, newReminder];
            }
        });
    }

    async function reloadReminders(auth) {
        if (auth?.tmp_has_access_to_reminder !== true) {
            return;
        }
        console.log("reload reminders");

        getUserDailyReminders(auth.id).then((newReminders) => {
            console.log('reload reminders newReminders :>> ', newReminders);
            // trigger in browser reminders that need be
            for (const nextRm of newReminders) {
                if (nextRm.triggered === true) {
                    continue;
                }

                if (nextRm.hour === null) {
                    addNotification(`Rappel: ${nextRm.lead_firstname} ${nextRm.lead_lastname}`, nextRm.reason || "");
                    setAsTriggered(nextRm.id);
                } else {
                    const minutesOfTheDay = nextRm.minute ? nextRm.minute : 0;
                    const delayInSeconds = Math.max(timeDiffFromNowInSeconds(nextRm.hour, minutesOfTheDay), 0);

                    setTimeout(() => {
                        addNotification(`Rappel: ${nextRm.lead_firstname} ${nextRm.lead_lastname}`, nextRm.reason || "");
                        setAsTriggered(nextRm.id);
                    }, delayInSeconds * 1000);
                }
            }

            // add active reminders to `members`
            const activeReminders = []; // active means its time to trigger happened
            for (const nextRm of newReminders) {
                if (nextRm.hour === null) {
                    activeReminders.push(nextRm);
                } else {
                    const minutesOfTheDay = nextRm.minute ? nextRm.minute : 0;
                    const delayInSeconds = Math.max(timeDiffFromNowInSeconds(nextRm.hour, minutesOfTheDay), 0);

                    setTimeout(() => {
                        addReminder(nextRm);
                    }, delayInSeconds * 1000);
                }
            }
            setReminders(activeReminders);
        });
    }

    useEffect(() => {
        if (!auth?.id) {
            return;
        }

        reloadReminders(auth);
        const intervalId = setInterval(() => {
            reloadReminders(auth);
        }, 30 * 60 * 1000); // Update every 30 minutes

        return () => clearInterval(intervalId);
    }, [auth]);

    return [reminders, reloadReminders, markReminderLocallyAsViewed];
};
