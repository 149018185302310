import React from "react";
import { markAsViewed } from "../../api/axios";
import PopupMessage from "./popup_message";


export default function PopupReminders({ auth, setToast, reminders, reloadReminders, markReminderLocallyAsViewed }) {
    if (reminders === null) {
        return null;
    }

    const validateCallback = (nextReminder) => {
        console.log("validateCallback", nextReminder);
        markReminderLocallyAsViewed(nextReminder.id)
        markAsViewed(nextReminder.id)
            .catch(err => {
                setToast({ text: "Echec, marquer comme vu.", severity: "error" });
                console.error(err);
            });
    }

    // find next reminder to view (or returns)
    const notViewed = reminders.filter(rem => rem.marked_as_viewed !== true);
    if (notViewed.length === 0) {
        return null;
    }
    const reminder = notViewed[0];

    let timingMessage = reminder.hour ? ` de ${reminder.hour}h` : "";
    timingMessage = (reminder.minute && reminder.hour) ? `${timingMessage}${reminder.minute}` : timingMessage;
    return <PopupMessage title={`Rappel${timingMessage}`}
        imgSrc={undefined}
        imgClass={undefined}
        texts={[`Contact: ${reminder.lead_firstname || ""} ${reminder.lead_lastname || ""}`,
        `Téléphone: ${reminder.lead_phone || ""}, Email: ${reminder.email || ""}`,
        `Description du rappel: ${reminder.reason || ""}`]}
        validateText={"Marquer comme vu"}
        validateCallback={() => validateCallback(reminder)} />;
}
