import { useContext, useState } from 'react';
import { handleNRP, storeFeatureUsage, updateLead } from '../api/axios';
import { Departements, LeadsFieldsInputType, LeadsFieldsShouldCapitalizeFirstLetter, LeadsFieldsWithDefinedValues, LeadStatus, ListsNicknames } from '../context/enums';

import React from 'react';
import AuthContext from '../context/AuthProvider';
import { isDefined } from '../lib/tools/basics';
import { date2str } from '../lib/tools/datetime';
import { capitalizeFirstLetter } from '../lib/tools/strings';


function InfoCol(props) {

    const { id, label, value, champ, updateCallback, updateUICallback, userId, shouldDisplayPlus } = props;
    const { isExpanded, setIsExpanded, setToast } = props;
    const [newValue, setNewValue] = useState(value);

    const updateChamp = () => {
        updateCallback(newValue);
        updateUICallback(champ, newValue).catch(err => {
            setToast({ text: "Echec, mise à jour des données.", severity: "error" });
            console.error(err);
        });
    }

    const getPlusSignal = () => {
        if (!shouldDisplayPlus) {
            return null;
        }

        const expandButtonImageSrc = isExpanded ? "./images/Rectangle-90.svg" : "./images/Vector-6.svg"
        return <div className="la_center-link-holder" key="plus">
            <span className="la_plus-info w-inline-block" onClick={() => setIsExpanded(prev => !prev)}>
                <img src={expandButtonImageSrc} loading="lazy" alt="" />
            </span>
        </div>
    }

    if (LeadsFieldsWithDefinedValues[champ]) {
        const optionsObject = LeadsFieldsWithDefinedValues[champ];
        return <div className="la_holder-input-info">
            <div className="la_text-block-32">Situation familiale</div>
            <select className="la_text-field-4 situation-familiale w-select"
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={updateChamp}
                value={newValue}>
                <option key={"empty"} value="">{"Inconnue"}</option>
                {Object.keys(optionsObject).map((key) => (
                    <option key={key} value={key}>
                        {optionsObject[key]}
                    </option>
                ))}
                {!Object.keys(optionsObject).includes(newValue)
                    ? <option key={"other__value"} value={newValue}>{newValue}</option>
                    : null}
            </select>
            {getPlusSignal()}
        </div>;
    }

    const shouldCapitalizeFirstLetter = Boolean(LeadsFieldsShouldCapitalizeFirstLetter[champ]);
    const newValueCleaned = shouldCapitalizeFirstLetter && newValue ? capitalizeFirstLetter(newValue) : newValue;

    const inputType = LeadsFieldsInputType[champ] ? LeadsFieldsInputType[champ] : "text";
    // pk  maxLength={255} 
    return (
        <div className="la_holder-input-info">
            <input className="la_text-field-4 w-input"
                maxLength={255}
                type={inputType}
                value={newValueCleaned || ""}
                onBlur={updateChamp}
                onChange={(e) => setNewValue(e.target.value)} />
            <div className="la_text-block-32">{label}</div>
            {getPlusSignal()}
        </div>
    );
}

function Lead(props) {

    const {
        id,
        firstname,
        lastname,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        commentaire,
        statut,
        income_tax,
        assigned_to,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        company_name,
        company_industry,
        region,
        job_started_on,
        data_source,
    } = props.lead;
    const {
        handleShowExternalTools,
        showExternalToolsButton,
        setExternalToolLead,
        setConfirmation,
        list,
        setPopupCreateReminderLeadId,
        reminders,
        setToast,
        handleLeadUpdated,
        handleUpdateStatutCounts,
    } = props;

    const { auth, color, secondaryColor } = useContext(AuthContext);

    const [focusName, setFocusName] = useState(false);

    const [isExpanded, setIsExpanded] = useState(false);

    const [newLastname, setNewLastname] = useState(lastname);
    const [newFirstname, setNewFirstname] = useState(firstname);
    const [newComment, setNewComment] = useState(commentaire);
    const [newEmail, setNewEmail] = useState(email);
    const [newPhone, setNewPhone] = useState(phone_number_concatenated);
    const [newZipcode, setNewZipcode] = useState(zipcode);
    const [newBirthYear, setNewBirthYear] = useState(year_of_birth);
    const [newHousingStatus, setNewHousingStatus] = useState(housing_status);
    const [newStatut, setNewStatut] = useState(statut);
    const [newIncomeTax, setNewIncomeTax] = useState(income_tax);
    const [newProfession, setNewProfession] = useState(profession);
    const [newFamily, setNewFamily] = useState(family);
    const [newLegalStatus, setNewLegalStatus] = useState(legal_status);
    const [newAdress, setNewAdress] = useState(adresse);
    const [newChildren, setNewChildren] = useState(enfant);
    const [newCity, setNewCity] = useState(city);
    const [newDepartement, setNewDepartement] = useState(departement);
    const [newCompanyName, setNewCompanyName] = useState(company_name);
    const [newJobStartedOn, setNewJobStartedOn] = useState(job_started_on);
    const [newCompanyIndustry, setNewCompanyIndustry] = useState(company_industry);
    const [newRegion, setNewRegion] = useState(region);

    console.log("props.lead", props.lead);

    const canTransferToSalesman = () => {
        return true;
        // return newStatut === LeadStatus["RDV_PRIS"] || newStatut === LeadStatus["RDV_HAPPENED"] || newStatut === LeadStatus["RDV_MISSED"] || newStatut === LeadStatus["SIGNE"];
    }

    async function updateLeadNUI(champ, value) {
        handleLeadUpdated(id, champ, value);
        const json = JSON.stringify({ champ, value, user_id: auth.id });
        return await updateLead(id, json);
    }

    const updateComment = () => {
        updateLeadNUI('commentaire', newComment)
            .then(() => {
                setToast({ text: "Commentaire mis à jour", duration: 2000, severity: "success" });
            }).catch(err => {
                setToast({ text: "Echec, mise à jour du commentaire", severity: "error" });
                console.error(err);
            });
    }

    const updateStatut = (e) => {
        setNewStatut(e.target.value);
        handleUpdateStatutCounts(statut, e.target.value);
        updateLeadNUI('statut', e.target.value)
            .then(() => {
                setToast({ text: "Statut mis à jour", duration: 2000, severity: "success" });
            }).catch(err => {
                setToast({ text: "Echec, mise à jour du statut", severity: "error" });
                console.error(err);
            });
    }

    const updateLastname = () => {
        updateLeadNUI('lastname', newLastname)
            .catch(err => {
                setToast({ text: "Echec, mise à jour du nom", severity: "error" });
                console.error(err);
            });
    }

    const updateFirstname = () => {
        updateLeadNUI('firstname', newFirstname)
            .catch(err => {
                setToast({ text: "Echec, mise à jour du prénom", severity: "error" });
                console.error(err);
            });
    }

    const handleNamesBlur = () => {
        updateLastname();
        updateFirstname();
    }

    const handleNamesContainerBlur = () => {
        setFocusName(false);
    }

    const getDataSourceDisplayValue = (value) => {
        if (value === "pages_jaunes") {
            return "Pages jaunes";
        } else {
            return "";
        }
    }

    const getLeadText = (addComment) => { // todo(1) rendre compatible B2B
        let text = `Noms: ${newFirstname + " " + newLastname}\n`;
        if (newEmail) {
            text += `Email: ${newEmail}\n`;
        }
        if (newPhone) {
            let phoneNb = newPhone.length < 10 ? "0" + newPhone : newPhone
            text += `Téléphone: ${phoneNb}\n`;
        }
        text += "\n";
        if (newHousingStatus) {
            text += `Statut de résidence: ${newHousingStatus}\n`;
        }
        if (newIncomeTax) {
            text += `Imposition: ${newIncomeTax}\n`;
        }
        if (newBirthYear) {
            text += `Année de naissance: ${newBirthYear}\n`;
        }
        if (newCity) {
            text += `Ville: ${city}\n`;
        }
        if (newZipcode) {
            text += `Code postal: ${newZipcode}\n`;
        }
        if (newProfession) {
            text += `Profession: ${newProfession}\n`;
        }
        if (newFamily) {
            text += `Situation familiale: ${newFamily}\n`;
        }
        if (newLegalStatus) {
            text += `Statuts juridique: ${newLegalStatus}\n`;
        }
        if (newAdress) {
            text += `Addresse: ${newAdress}\n`;
        }
        if (newChildren) {
            text += `Enfant ?: ${newChildren}\n`;
        }
        if (addComment && newComment) {
            text += `\n\nCommentaire: ${newComment}\n`;
        }

        return text;
    }

    const handleCopy = () => {
        storeFeatureUsage(auth.id, "copypaste_lead", JSON.stringify({ leadId: id }));
        navigator.clipboard.writeText(getLeadText(true))
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    const handleExternalToolClick = () => {
        storeFeatureUsage(auth.id, "360_open", JSON.stringify({ leadId: id }));
        setExternalToolLead(props.lead);
        handleShowExternalTools();
    };

    const handleTransferToSalesman = () => {
        setConfirmation({
            "text": "Transférez ce lead à un vendeur.",
            "showYesButton": false,
            "showNoButton": false,
            "specialPopup": { type: "transfer2Salesman", leadId: id, companyId: auth.company_id },
            "confirmCallback": (userId) => {
                storeFeatureUsage(auth.id, "transfer2salesman", JSON.stringify({ leadId: id, userId }));
            }
        });
    };

    const getDisplayedDepartement = () => {
        const departementName = Departements[newDepartement];
        if (departementName) {
            return `${departementName} (${newDepartement})`;
        } else {
            return newDepartement;
        }
    }

    function getInfoCol(champ, idx, fieldsCount) {
        const isLast = idx === fieldsCount - 1;
        const shouldDisplayPlus = (isExpanded && isLast && idx > 2) || (!isExpanded && fieldsCount > 3 && idx === 2);

        if (champ === "phone_number_concatenated") {
            return <InfoCol
                key={champ}
                label="Téléphone"
                id={id}
                champ={champ}
                value={newPhone.length < 10 ? "0" + newPhone : newPhone}
                updateCallback={setNewPhone}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "email") {
            return <InfoCol
                key={champ}
                label="Email"
                id={id}
                champ={champ}
                value={newEmail}
                updateCallback={setNewEmail}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "city") {
            return <InfoCol
                key={champ}
                label="Ville"
                id={id}
                champ={champ}
                value={newCity}
                updateCallback={setNewCity}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "departement") {
            return <InfoCol
                key={champ}
                label="Departement"
                id={id}
                champ={champ}
                value={getDisplayedDepartement()}
                updateCallback={setNewDepartement}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "region") {
            return <InfoCol
                key={champ}
                label="Région"
                id={id}
                champ={champ}
                value={newRegion}
                updateCallback={setNewRegion}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "year_of_birth") {
            return <InfoCol
                key={champ}
                label="Année de naissance"
                id={id}
                champ={champ}
                value={newBirthYear}
                updateCallback={setNewBirthYear}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "income_tax") {
            return <InfoCol
                key={champ}
                label="Imposition"
                id={id}
                champ={champ}
                value={newIncomeTax}
                updateCallback={setNewIncomeTax}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "housing_status") {
            return <InfoCol
                key={champ}
                label="Statut de résidence"
                id={id}
                champ={champ}
                value={newHousingStatus}
                updateCallback={setNewHousingStatus}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "family") {
            return <InfoCol
                key={champ}
                label="Situation familiale"
                id={id}
                champ={champ}
                value={newFamily}
                updateCallback={setNewFamily}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "zipcode") {
            return <InfoCol
                key={champ}
                label="Code postal"
                id={id}
                champ={champ}
                value={newZipcode}
                updateCallback={setNewZipcode}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "profession") {
            return <InfoCol
                key={champ}
                label="Profession"
                id={id}
                champ={champ}
                value={newProfession}
                updateCallback={setNewProfession}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "legal_status") {
            return <InfoCol
                key={champ}
                label="Statuts juridique"
                id={id}
                champ={champ}
                value={newLegalStatus}
                updateCallback={setNewLegalStatus}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "adresse") {
            return <InfoCol
                key={champ}
                label="Addresse"
                id={id}
                champ={champ}
                value={newAdress}
                updateCallback={setNewAdress}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "company_indudstry") {
            return <InfoCol
                key={champ}
                label="Domaine de l'entreprise"
                id={id}
                champ={champ}
                value={newCompanyIndustry}
                updateCallback={setNewCompanyIndustry}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "company_name") {
            return <InfoCol
                key={champ}
                label="Entreprise"
                id={id}
                champ={champ}
                value={newCompanyName}
                updateCallback={setNewCompanyName}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "job_started_on") {
            return <InfoCol
                key={champ}
                label="Date de début d'activité"
                id={id}
                champ={champ}
                value={newJobStartedOn}
                updateCallback={setNewJobStartedOn}
                updateUICallback={updateLeadNUI}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else if (champ === "data_source") {
            return <InfoCol
                key={champ}
                label="Source"
                id={id}
                champ={champ}
                value={getDataSourceDisplayValue(data_source)}
                updateCallback={() => { }}
                userId={auth.id}
                secondaryColor={secondaryColor}
                shouldDisplayPlus={shouldDisplayPlus}
                isExpanded={isExpanded}
                setToast={setToast}
                setIsExpanded={setIsExpanded} />;
        } else {
            return null;
        }
    }

    const reorderFieldsByUsefullness = (fields) => {
        const result = fields.filter(fi => isDefined(props.lead?.[fi]));
        return result.concat(fields.filter(fi => !isDefined(props.lead?.[fi])));
    }

    const getInfoCols = (fields) => {
        const fieldsOrdered = reorderFieldsByUsefullness(fields);

        return fieldsOrdered.map((nextField, idx) => {
            if (!isExpanded && idx >= 3) {
                return null;
            }
            return getInfoCol(nextField, idx, fields.length);
        });
    }

    const getColumnInfos = (title, fields) => {
        return <div key={title} className="la_verticale-informations">
            <div className="la_type-information">
                <div className="la_lad_category_text">{title}</div>
            </div>
            <div className="la_form-informations">
                <div className="w-form">
                    {getInfoCols(fields)}
                </div>
            </div>
        </div>;
    }

    const getFieldsColumnsComponent = () => {
        if (list.nickname === ListsNicknames["B2B_MEDICAL"] || list.nickname === ListsNicknames["B2B_DIRECTOR"]) {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["profession", "job_started_on", "data_source", "legal_status",
                    "housing_status", "family", "income_tax"]),
            ];
        } else if (list.nickname === ListsNicknames["ALL"]) {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["income_tax", "profession", "housing_status", "legal_status", "family",
                    "job_started_on", "data_source",
                ]),
            ];
        } else {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["income_tax", "profession", "housing_status", "legal_status", "family"]),
            ];
        }
    }

    const handleNRPClick = () => {
        setNewStatut(LeadStatus["NRP"]);
        handleNRP(id, auth.id);
    }

    const getReminderComponent = () => {
        if (reminders.length === 0) {
            return null;
        }

        const firstReminder = reminders[0];
        const reminderHourSuffix = firstReminder.hour ? ` ${firstReminder.hour}h` : '';

        /* todo(2) gerer plus qu'un reminder ici!! et ameliorer UI sur grosses reasons par exemple */
        return <div className="la_calendar-button w-inline-block">
            <img src="./images/mingcute_calendar-fill.svg" loading="lazy" alt="" />
            <div className="la_color-stats"></div>

            <div className="la_information-de-la-vente">
                <div className="la-holder-text-variable">
                    <div className="la_text_block-52">Date&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="la_text_block-52">{date2str(firstReminder.reminder_date)}{reminderHourSuffix}</div>
                </div>
                <div className="la-holder-text-variable">
                    <div className="la_text_block-52">Raison&nbsp;:</div>
                    <div className="la_text_block-52">{firstReminder.reason}</div>
                </div>
                <img src="./images/Triangle.svg" loading="lazy" alt="" className="image-20" />
            </div>
        </div>;
    }

    if (!auth) {
        return null;
    }

    const getCommentLinesCount = (txt) => {
        if (!txt) {
            return 1;
        }

        const potentialLines = txt.length / 50; // one line could rarely have more than 50 characters so we split
        const lineCount = txt.split('\n').length;
        return Math.max(Math.min(4, lineCount), 1, Math.floor(potentialLines));
    }

    return (
        <div className="la_the-lead">
            <div className="la_holder-name-and-status">
                {!focusName && <div className="la_name-lead" onClick={() => setFocusName(true)}>{newFirstname + " " + newLastname}</div>}
                {focusName && <div onBlur={handleNamesContainerBlur} className="la_holder_names">
                    <div key="firstname" className="la_form-block-5 w-form">
                        <input className="la_names w-input"
                            maxLength={256}
                            placeholder=""
                            type="text"
                            value={capitalizeFirstLetter(newFirstname) || ""}
                            onBlur={handleNamesBlur}
                            onChange={(e) => setNewFirstname(e.target.value)} />
                    </div>
                    <div key="lastname" className="la_form-block-5 w-form">
                        <input className="la_names w-input"
                            maxLength={256}
                            placeholder=""
                            type="text"
                            value={capitalizeFirstLetter(newLastname) || ""}
                            onBlur={handleNamesBlur}
                            onChange={(e) => setNewLastname(e.target.value)} />
                    </div>
                </div>}

                <div className="la_options-lead">
                    {auth?.tmp_has_access_to_reminder ? <div className="la_calendar-button w-inline-block" onClick={() => setPopupCreateReminderLeadId(id)}>
                        <img src="./images/lsicon_work-order-appointment-outline.svg" loading="lazy" alt="" />
                    </div> : null}
                    {getReminderComponent()}
                    <div className="la_status-drop-down w-form">
                        <div className="la_form-4">
                            <select className="la_status-dropdown w-select"
                                value={newStatut ? newStatut : LeadStatus["SANS_STATUT"]}
                                onChange={updateStatut}>
                                {Object.values(LeadStatus).map((l, i) => {
                                    return <option key={i} value={l}>{l}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="la_low-partp-lead">
                <div className="la_contact-information">Contact Informations</div>
                <div className="la_holder-c_i-spaced">
                    {getFieldsColumnsComponent()}
                </div>

                <div className="la_holder-comments-commandes">
                    <div className="la_form-block-2 w-form">
                        <div className="la_form-2">
                            {/* <input className="la_text-field-5 w-input"
                                maxLength={8000}
                                type="text"
                                value={newComment || ""}
                                onBlur={updateComment}
                                onChange={(e) => setNewComment(e.target.value)} /> */}
                            <textarea
                                className="la_text-field-5 w-input"
                                maxLength={8000}
                                value={newComment || ""}
                                onBlur={updateComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                rows={getCommentLinesCount(newComment)} />
                            <img src="./images/comment-2-svgrepo-com-1.svg" loading="lazy" alt="" className="la_image-14" />
                        </div>
                    </div>
                    <div className="la_commandes">
                        {/* <div className="la_commande-link w-inline-block" onClick={handleNRPClick}>
                            <div className="la_text-block-44">NRP</div>
                        </div> */}
                        <div key="copy" className="la_commande-link w-inline-block" onClick={handleCopy}>
                            <img src="./images/copy-svgrepo-com-3.svg" loading="lazy" alt="" />
                        </div>
                        {showExternalToolsButton ? <div key="360"
                            onClick={handleExternalToolClick}
                            className="la_commande-link w-inline-block">
                            <img src="./images/bank-svgrepo-com-1-1.svg" loading="lazy" alt="" />
                        </div> : null}
                        {canTransferToSalesman() ? <div key="transfer_lead"
                            onClick={handleTransferToSalesman}
                            className="la_commande-link w-inline-block">
                            <img src="./images/Frame-172.svg" loading="lazy" alt="" />
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Lead;
