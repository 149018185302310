function isDefined(value) {
    return value !== null && value !== undefined && value !== "";
}

function getRandomValueFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

module.exports = {
    isDefined,
    getRandomValueFromArray
};
