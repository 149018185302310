import { Box, CardMedia, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { storeFeatureUsage } from './api/axios';
import EmbededVideo from './component/formation/embeded_video';
import Header from './component/header';
import PopupReminders from './component/popups/popup_reminders';
import Toast from './component/ux/toast';
import AuthContext from './context/AuthProvider';
import { UserType } from './context/enums';
import { useReminders } from './lib/reminders/useReminders';
import './style/Formation.css';


export default function Formation() {

    const { auth, color, secondaryColor, tertiaryColor } = useContext(AuthContext);

    const [toast, setToast] = useState(null);

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    const [embededVideo, setEmbededVideo] = useState({ show: false, link: "" });

    const closeEmbededVideo = () => {
        setEmbededVideo({ show: false, link: "" });
    }

    useEffect(() => {
        storeFeatureUsage(auth.id, "open_learning_page", null);
    }, [auth]);

    const getVideoBlock = (title, description, link, duration, imageFile) => {
        return (
            <Grid item xs={3.5} justifyContent="center">
                {/* <a href={link} style={{ textDecoration: 'none', color: 'black' }}> */}
                <Paper
                    onClick={() => setEmbededVideo({ show: true, link })}
                    elevation={3}
                    sx={{
                        backgroundColor: color,
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                        cursor: 'pointer',
                    }}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={"https://app.lead-access.fr/images/formation/" + imageFile}
                        alt="Video Thumbnail"
                        sx={{ borderRadius: '4px' }} />
                    <Typography variant="h6" sx={{ color: 'white', marginTop: '10px' }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'white', marginTop: '5px', marginBottom: '10px', fontWeight: 'normal', fontSize: '14px' }}>
                        {description}
                    </Typography>
                    <div className={"formation_enlightment"} style={{ backgroundColor: tertiaryColor }}>{duration}</div>
                </Paper>
                {/* </a> */}
            </Grid>
        );
    }

    const getAdminPart = () => {
        if (auth.user_type !== UserType["MANAGER"]) {
            return null;
        }

        return <>
            <Grid item xs={12}>
                <h3>Management & Administration 📱</h3>
            </Grid>
            <Grid container spacing={2} style={{ padding: '10px' }}>
                {/*
                    video links: https://www.loom.com/share/5a536f8485134f5ba6c2106e2ebabcc0?sid=ad8d1fd2-2f0d-49ed-9f06-4c4ad1829dc0
                        https://www.loom.com/share/095ad59de83d43fbb55b1751eeeec89a?sid=85b8b79e-ea37-41b6-83d2-10b0aecbf825
                        https://www.loom.com/share/8044240fcaf6432c8e07cf3d67325298?sid=b60af9d2-e966-481f-b632-1bb1d3e896ef
                */}
                {getVideoBlock("Gestion des Comptes", "", "https://www.loom.com/embed/5a536f8485134f5ba6c2106e2ebabcc0?sid=e7234d08-313b-4510-82cd-6094b9e70f06", "1 minute", "leader.png")}
                {getVideoBlock("Gestion des Leads", "Attribution des leads aux collaborateurs", "https://www.loom.com/embed/095ad59de83d43fbb55b1751eeeec89a?sid=0ea4a4b2-faa3-465f-b36c-52da1e68a9af", "1 minute", "leader.png")}
                {getVideoBlock("Métriques des Collaborateurs", "Présentation de la partie métrique pour les managers", "https://www.loom.com/embed/8044240fcaf6432c8e07cf3d67325298?sid=ad564f02-aaf6-48ce-a452-49bab8715bb0", "1 minute", "leader.png")}
            </Grid>
        </>
    }

    const getCentralComponent = () => {
        return (
            <Grid container spacing={2} style={{ padding: '10px', paddingLeft: '40px', paddingRight: '40px' }}>
                <Grid item xs={12}>
                    <h1 style={{ color: color }}>Formation</h1>
                </Grid>

                <Grid item xs={12}>
                    <h3>Les essentiels 🎁</h3>
                </Grid>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                    {/* 
                        video links: https://www.loom.com/share/16c30731be9142d2bbf1fcf162e61c05?sid=8fa6d500-61f0-4efa-aeb0-150fce6e6b65
                            https://www.loom.com/share/01446594360442a78469c60152f6a23a?sid=6b2d24e0-5109-46ee-8d8c-93e95a96c47b
                    */}
                    {getVideoBlock("Ecran principal", "Présentation de l'écran principal de Lead Access", "https://www.loom.com/embed/16c30731be9142d2bbf1fcf162e61c05?sid=47e67e62-7c28-4b4a-ab81-888d70e14067", "2 minutes 30", "matrix.jpg")}
                    {getVideoBlock("Recherche et Filtres", "Comment Rechercher et Filtrer des Leads", "https://www.loom.com/embed/01446594360442a78469c60152f6a23a?sid=6accf44e-44b5-4e10-b9b0-9854ac6a7ea5", "1 minute", "matrix.jpg")}
                </Grid>

                <Grid item xs={12}>
                    <h3>Bugs 🛠️</h3>
                </Grid>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                    {/* 
                        video links: https://www.loom.com/embed/97afb8e67fa24fa9b924075d8ab94c68?sid=6513852a-4f73-4557-ab78-9127ff03d864
                    */}
                    {getVideoBlock("Bug, pas de leads sur l'interface", "Comment corriger ce bug sur Safari", "https://www.loom.com/embed/97afb8e67fa24fa9b924075d8ab94c68?sid=6513852a-4f73-4557-ab78-9127ff03d864", "30 secondes", "matrix.jpg")}
                </Grid>

                <Grid item xs={12}>
                    <h3>Prospecter des particuliers 🎯</h3>
                </Grid>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                    {getVideoBlock("Déballe commerciale", "", "https://www.loom.com/embed/77d8f597066e4c7889dceec743c22570?sid=e514e67d-33e7-4dd3-b52f-f8d2cd5576cb", "5 minutes", "cheetah.jpg")}
                    {getVideoBlock("Listes des objections courantes", "", "https://www.loom.com/embed/10ddab4185394fbcac400e811cb45d63?sid=95ecc5b1-eec9-44c0-b877-fdc550429e33", "5 minutes", "cheetah.jpg")}
                    {getVideoBlock("Améliorer sa prospection téléphonique", "", "https://www.loom.com/embed/c28ad49d2bdf42f583f2d2a301525eeb?sid=cd2213d9-759a-4cea-8aaf-28552a2a5365", "5 minutes", "cheetah.jpg")}
                </Grid>

                {getAdminPart()}
            </Grid>
        );
    }

    if (!auth) {
        return null;
    }

    if (auth.company_nickname === "bien" || auth.company_nickname === "bienstra") { // todo(1) move bien aside
        return null;
    }

    return (
        <div className="la_body">
            <section className="la_section-6">
                {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
                <PopupReminders reminders={reminders}
                    reloadReminders={reloadReminders}
                    markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                    auth={auth}
                    setToast={setToast} />
                {embededVideo.show ? <EmbededVideo link={embededVideo.link} handleClose={closeEmbededVideo} /> : null}
                <Header />
                <div className='la_column_section large'>
                    <div className="old_body">
                        <Box>
                            {getCentralComponent()}
                        </Box>
                    </div>
                </div>
            </section>
        </div>
    );
};
