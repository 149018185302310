import React, { useContext, useState } from "react";
import Calendar from "react-calendar/dist/cjs/Calendar.js";
import { addReminder, storeFeatureUsage } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { date2UTCDate, getTodayUTC } from "../../lib/tools/datetime";
import "../../style/Calendar.css";


const PopupAddReminder = ({ leadId, setToast, successCallback, closingCallback }) => {
    const { auth } = useContext(AuthContext);

    const [hour, setHour] = useState("__undefined__");
    const [minute, setMinute] = useState("__undefined__");
    const [reason, setReason] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);  // datetime object

    const handleFinished = () => {
        closingCallback();
    };

    const handleCreateClick = () => {
        if (selectedDate === null) {
            setToast({ text: "Aucune date séléctionnée", severity: "error" });
            return;
        }

        const cleanedHour = hour === "__undefined__" ? null : Number(hour);
        const cleanedMinute = minute === "__undefined__" ? null : Number(minute);
        storeFeatureUsage(auth.id, "reminder_add", { leadId, selectedDate, cleanedHour, cleanedMinute, reason });
        addReminder(auth.id, leadId, selectedDate, cleanedHour, cleanedMinute, reason).then(() => {
            handleFinished();
            successCallback();
            setToast({ text: "Rappel ajouté", severity: "success" });
        }).catch(err => {
            console.error(err);
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
        });
    }

    const handleDateChange = (date) => {
        setSelectedDate(date2UTCDate(date));
    };

    const isTileDisabled = ({ date, view }) => {
        return false;
    };

    const allHours = Array.from({ length: 18 }, (_, i) => i + 6);

    if (!auth) {
        return null;
    }

    return (
        <section className="la_transfer_lead_popup">
            <div className="la_pop-up-send-lead">
                <div className="la_text-block-40">Créer un rappel</div>
                <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={getTodayUTC()}
                    selectRange={false}
                    tileDisabled={isTileDisabled}
                    locale='fr-FR'
                    maxDetail='month'
                    showDoubleView={false} // Hide the double view mode
                />
                <input className="la_tf_8 w-input"
                    maxLength={255}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Raison"
                    type="text" />
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', gap: '10px' }}>
                    <select value={hour}
                        className="la_select-field w-select"
                        onChange={ev => setHour(ev.target.value)}>
                        <option value="__undefined__">{"Heure (optionnel)"}</option>
                        {allHours.map((hour) => {
                            return <option key={hour} value={hour}>{hour} h</option>;
                        })}
                    </select>
                    <select value={minute}
                        className="la_select-field w-select"
                        onChange={ev => setMinute(ev.target.value)}>
                        <option value="__undefined__">{"Minute (optionnel)"}</option>
                        <option value={0}>0 m</option>
                        <option value={5}>5 m</option>
                        <option value={10}>10 m</option>
                        <option value={15}>15 m</option>
                        <option value={20}>20 m</option>
                        <option value={25}>25 m</option>
                        <option value={30}>30 m</option>
                        <option value={35}>35 m</option>
                        <option value={40}>40 m</option>
                        <option value={45}>45 m</option>
                        <option value={50}>50 m</option>
                        <option value={55}>55 m</option>
                    </select>
                </div>
                <div className="la_button-2 w-button" onClick={handleCreateClick}>Créer</div>
                <div className="la_close-button w-inline-block" onClick={handleFinished}>
                    <img src="./images/close-svgrepo-com-2-2-1.svg" loading="lazy" alt="" />
                </div>
            </div>
        </section>
    );
};

export default PopupAddReminder;
