import { Box, CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import { addB2BMedicalLeads, addMessageToThread, createChatbotThreads, removeLeads, storeFeatureUsage } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { Jobs } from "../../context/enums";
import { useJobCategories } from "../../lib/leads/useJobCategories";
import { setNumericInput } from "../../lib/tools/strings";

const IntroductionMessages = [{
    role: "assistant",
    text: `Bonjour, je suis l'IA de NOVA. Je peux vous aider à réaliser de nombreuses tâches comme ajouter ou enlever des leads, 
    demander à ce qu'un bug soit corrigé.`
},
{
    role: "assistant",
    text: `Il vous suffit pour cela de m'écrire un message avec le champ textuel ci-dessous..`
},
{
    role: "assistant",
    text: `Si vous ne savez pas quoi me demander, commencer par une requête simple, exemple "Quels sont les leads disponibles ?".`
}]

export default function PopupChatbot({ handleCloseButton }) { // todo(1) lui ajouter une key qu il se recree a chaque fois?
    const { auth, isPreprod } = useContext(AuthContext);

    const reportBugUrl = 'https://ballistic-death-b8e.notion.site/193ecce8803c80589bc1d9d88e1a4ea2';

    const jobCategories = useJobCategories({ auth });

    const classesSuffix = isPreprod ? " preprod" : "";

    const [isWaiting, setIsWaiting] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState("");
    const [conversationId, setConversationId] = useState(null); // if null then no thread was started

    const [leadsCount, setLeadsCount] = useState("");
    const [selectedJobCategory, setSelectedJobCategory] = useState("__all__");
    const [selectedJob, setSelectedJob] = useState("__all__");

    const handleAddUserMessage = () => {
        setInputText("");
        setIsWaiting(true);
        if (conversationId === null) {
            setMessages([{ role: "user", text: inputText }]);
            createChatbotThreads(inputText).then((data) => {
                setConversationId(data.data.conversation_id);
                setMessages(data.data.messages);
                setIsWaiting(false);
                // reuploadLeads(); // todo(1) gerer cela quand une instruction est renvoyée
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
        } else {
            setMessages(prevMessages => [...prevMessages, { role: "user", text: inputText }]);
            addMessageToThread(inputText, conversationId).then((data) => {
                setMessages(data.data.messages);
                setIsWaiting(false);
                // reuploadLeads(); // todo(1) gerer cela quand une instruction est renvoyée
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
        }
    }

    const handleAddB2BMedLeads = () => {
        if (!leadsCount) {
            setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Veuillez indiquer un nombre de leads." }]);
            return;
        }

        setInputText("");
        setIsWaiting(true);
        storeFeatureUsage(auth.id, "chatbot_add_b2bmed", null);
        addB2BMedicalLeads(leadsCount ? leadsCount : 0,
            selectedJobCategory === "__all__" ? null : selectedJobCategory,
            selectedJob === "__all__" ? null : selectedJob)
            .then((data) => {
                const countAdded = data.data.count_added;
                const creditsLeft = data.data.credits_left;
                const error = data.data.error;

                let message = '';
                if (error === true) {
                    message = "Erreur, veuillez réessayer.";
                } else if (countAdded < creditsLeft) {
                    message = `${countAdded} leads ont été ajoutés, il n'y en avait plus de disponibles selon vos critères.`;
                } else if (creditsLeft < leadsCount) {
                    message = `${countAdded} leads ont été ajoutés, il ne vous restait que ${creditsLeft} crédits disponible aujourd'hui.`;
                } else {
                    message = `Les ${countAdded} leads ont été ajoutés avec succès.`;
                }

                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: message }]);
                setIsWaiting(false);
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
    }

    const handleRemoveNotInterestedLeads = () => {
        setInputText("");
        setIsWaiting(true);
        storeFeatureUsage(auth.id, "chatbot_remove_leads_except_interste", null);
        removeLeads(auth.id, true)
            .then((data) => {
                const removedCount = data.data.count_removed;
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: `${removedCount} leads enlevés avec succès.` }]);
                setIsWaiting(false);
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddUserMessage();
        }
    };

    console.log('conversationId :>> ', conversationId);

    const getMessageComponent = (msg, idx) => {
        if (msg.role === "assistant" && msg.type === "display_add_b2bmed") {
            return <div className="la_ai-messages">
                <img src="./images/Profile-nova.svg" loading="lazy" alt="" className="image-26" />
                <div className="text-block-52">Sélectionnez les leads B2B Médical que vous voulez vous attribuez</div>
                <div className="form-block-9 w-form">
                    <div className="form-3">
                        <label htmlFor="name-3" className="text-block-52">Nombre de leads</label>
                        <input className="text-field-11 w-input"
                            maxLength={256}
                            placeholder="Entrer le nombre"
                            value={leadsCount}
                            onChange={(e) => setNumericInput(e, setLeadsCount)}
                            type="text" />
                        {/* <label htmlFor="email" className="text-block-52">Département</label>
                        <input className="text-field-11 w-input" maxLength={256} placeholder="Entrer le nombre" type="email" required={true} /> */}
                        <label htmlFor="email-2" className="text-block-52">Catégories de métiers</label>
                        <div data-hover="false" data-delay="0" className="w-dropdown">
                            <select className="dropdown-toggle w-select"
                                value={selectedJobCategory}
                                onChange={(e) => setSelectedJobCategory(e.target.value)}>
                                <option key="__all__" value="__all__">
                                    Toutes les catégories
                                </option>
                                {jobCategories.map((jobCategory) => {
                                    return <option key={jobCategory.id} value={jobCategory.id}>
                                        {jobCategory.name}
                                    </option>
                                })}
                            </select>
                        </div>

                        <label htmlFor="email-2" className="text-block-52">Métiers</label>
                        <div data-hover="false" data-delay="0" className="w-dropdown">
                            <select className="dropdown-toggle w-select"
                                value={selectedJob}
                                onChange={(e) => setSelectedJob(e.target.value)}>
                                <option key="__all__" value="__all__">
                                    Tous les métiers
                                </option>
                                {Jobs.map((job) => <option key={job}
                                    value={job}>
                                    {job}
                                </option>)}
                            </select>
                        </div>
                        {/* <label htmlFor="email-2" className="text-block-52">Région</label> */}
                        {/* <div data-hover="false" data-delay="0" className="w-dropdown">
                            <div className="dropdown-toggle w-dropdown-toggle">
                                <div className="icon-4 w-icon-dropdown-toggle"></div>
                                <div className="text-block-73">Sélectionnez la région</div>
                            </div>
                            <nav className="dropdown-list w-dropdown-list">
                                <div>Link 1</div>
                                <div>Link 2</div>
                                <div>Link 3</div>
                            </nav>
                        </div> */}
                        <div className="button-2 w-button" onClick={handleAddB2BMedLeads}>
                            Attribuez les leads
                        </div>
                    </div>
                </div>
            </div>;
        } else if (msg.role === "assistant" && msg.type === "display_bug_report") {
            return <div key={idx} className="la_ai-messages">
                <div className="text-block-52">
                    Vous pouvez reporter un bug en cliquant sur le bouton ci-dessous.
                </div>
                <div className="button-2 w-button" onClick={() => window.open(reportBugUrl, '_blank')}>
                    Reporter un bug
                </div>
                <img src="./images/Profile-nova.svg" loading="lazy" alt="" className="image-26" />
            </div>;
        } else if (msg.role === "assistant" && msg.type === "remove_leads") {
            return <div key={idx} className="la_ai-messages">
                <div className="text-block-52">
                    Vous pouvez enlever vos leads.
                    Vos leads dont les status sont: Intéressés, RDV pris, RDV annulé, RDV non pris, ou Clients ne seront pas enlevés.
                </div>
                <div className="button-2 w-button" onClick={handleRemoveNotInterestedLeads}>
                    Enlever les leads
                </div>
                <img src="./images/Profile-nova.svg" loading="lazy" alt="" className="image-26" />
            </div>;
        } else if (msg.role === "assistant") {
            return <div key={idx} className="la_ai-messages">
                <div className="text-block-52">{msg.text}</div>
                <img src="./images/Profile-nova.svg" loading="lazy" alt="" className="image-26" />
            </div>;
        } else if (msg.role === "user") {
            return <div key={idx} className="la_user-message">
                <div className="la_user-msg">
                    <div className="text-block-53">{msg.text}</div>
                </div>
            </div>;
        }
    }

    return <section className={`la_chatbot-section _2${classesSuffix}`}>
        <div className="la_holder-input-title conversation">
            <h1 className="heading-10 conversation">Comment puis-je vous aidez&nbsp;?</h1>
            {IntroductionMessages.concat(messages).map((msg, idx) => getMessageComponent(msg, idx))}
            {isWaiting && <Box sx={{ display: 'flex', paddingTop: '10px' }}>
                <CircularProgress />
            </Box>}

            <div className="la_holder-input-down conversation">
                <div className="form-block-6 conversation w-form">
                    <div className="la_relative-holder-form">
                        <input className="la_input-chatbot w-input"
                            maxLength={256}
                            placeholder="Message NOVA AI..."
                            type="text"
                            value={inputText}
                            onChange={e => { setInputText(e.target.value) }}
                            onKeyDown={handleKeyDown} />
                        <div className="la-button-search w-inline-block" onClick={handleAddUserMessage}>
                            <img src="./images/pajamas_search.svg" loading="lazy" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`la_button-close w-inline-block${classesSuffix}`} onClick={handleCloseButton}>
            <img src="./images/close-svgrepo-com-2-2.svg" loading="lazy" alt="" className="image-21" />
        </div>
    </section>
}
