import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { Metrics } from "../../context/enums";
import GraphUsageMetrics from "./graphs/graph_usage_metrics";


export default function FeaturesUsage({ color, companies }) {
    // todo(1) finir probleme comment splitter par companies les lines

    const [companyId, setCompanyId] = useState("__undefined__");
    const [duration, setDuration] = useState(120);

    // todo(1) bloc select company en double, ainsi que son state
    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>{"Utilisation des features"}</h1>
            </Grid>

            <Grid item xs={12} key="select_company">
                <InputLabel>Sélectionnez une entreprise:</InputLabel>
                <Select value={companyId} onChange={ev => setCompanyId(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                    <MenuItem value="__all__">Toutes les entreprises</MenuItem>;
                    {companies.filter((cpny) => cpny.nickname !== "la" && cpny.nickname !== "test").map((cpny, idx) => {
                        return <MenuItem key={cpny.id} value={cpny.id}>{cpny.name}</MenuItem>;
                    })}
                </Select>
                <br />
            </Grid>

            {companyId === "__undefined__"
                ? null
                : Object.keys(Metrics).map(metric => {
                    return <GraphUsageMetrics key={`${companyId}_${metric}_${duration}`}
                        companyId={companyId === "__all__" ? null : companyId}
                        duration={duration}
                        metric={metric} />
                })}
        </Grid>
    );
}
