export const Version = "v3.5.0";
export const AdminVersion = "v1.4.2";

export const UserType = {
    USER: "Collaborateur",
    MANAGER: "Manager"
}

export const ListsNicknames = {
    B2C: "b2c",
    B2B_DIRECTOR: "b2bdir",
    B2B_MEDICAL: "b2bmed",
    ALL: "__all_company_leads__",
}

export const FamilySituations = {
    MARIED: "Marié",
    DIVORCED: "Divorcé",
    SINGLE: "Célibataire",
    PACS: "Pacsé"
}

export const LeadsFieldsWithDefinedValues = {
    "family": FamilySituations,
}

export const LeadsFieldsInputType = {
    "phone_number_concatenated": "tel",
}

export const LeadsFieldsShouldCapitalizeFirstLetter = {
    "city": true,
    "firstname": true,
    "lastname": true,
}

export const LeadStatus = {
    SANS_STATUT: "Sans statut",
    NRP: "NRP",
    RAPPELER: "A rappeler",
    NULL: "Nul",
    PAS_INTERESSE: "Pas intéressé",
    FAKE_NUM: "Faux numéro",
    INTERESSE: "Intéressé",
    RDV_PRIS: "RDV pris",
    RDV_HAPPENED: "RDV honoré",
    SIGNE: "Signé",
    RDV_MISSED: "RDV manqué"
}

export const StatusColor = {
    "Sans statut": "",
    "NRP": "",
    "A rappeler": "_2",
    "Nul": "_3",
    "Pas intéressé": "_4",
    "Faux numéro": "_5",
    "Intéressé": "_6",
    "RDV pris": "_7",
    "RDV honoré": "_8",
    "Signé": "_9",
    "RDV manqué": "_10",
}

export const Region = {
    IDF: {
        display: "Ile-de-France / Hauts-de-France", // todo(3) error lacking hauts de france
        CodePostal: ["75", "77", "78", "91", "92", "93", "94", "95"]
    },
    PACA: {
        display: "Provence-Alpes-Côte d’Azur",
        CodePostal: ["84", "83", "04", "05", "06", "13"]
    },
    LOIRE: {
        display: "Pays de la Loire",
        CodePostal: ["85", "72", "53", "49", "44"]
    },
    BRETAGNE: {
        display: "Bretagne",
        CodePostal: ["56", "35", "29", "22"]
    },
    OCCITANIE: {
        display: "Occitanie",
        CodePostal: ["82", "81", "66", "65", "48"]
    },
    AQUITAINE: {
        display: "Nouvelle-Aquitaine",
        CodePostal: ["87", "86", "79", "64", "47"]
    },
    AUVERGNE: {
        display: "Auvergne-Rhône-Alpes",
        CodePostal: ["74", "73", "69", "63", "43"]
    },
    BOURGOGNE: {
        display: "Bourgogne-Franche-Comté",
        CodePostal: ["90", "89", "71", "70", "58"]
    },
    GRANDEST: {
        display: "Grand Est",
        CodePostal: ["88", "68", "67", "57", "55"]
    },
    NORMANDIE: {
        display: "Normandie",
        CodePostal: ["76", "61", "50", "27", "14"]
    },
    REUNION: {
        display: "La Réunion",
        CodePostal: ["974"]
    },
    MARTINIQUE: {
        display: "Martinique",
        CodePostal: ["972"]
    },
    MAYOTTE: {
        display: "Mayotte",
        CodePostal: ["976"]
    },
    GUYANE: {
        display: "Guyane",
        CodePostal: ["973"]
    },
    GUADELOUPE: {
        display: "Guadeloupe",
        CodePostal: ["971"]
    },
    CORSE: {
        display: "Corse",
        CodePostal: ["2A", "2B"]
    },
    CENTREVAL: {
        display: "Centre-Val de Loire",
        CodePostal: ["45", "41", "37", "36", "28"]
    },
}

export const Departements = {
    "1": "Ain",
    "2": "Aisne",
    "3": "Allier",
    "4": "Alpes-de-Haute-Provence",
    "5": "Hautes-Alpes",
    "6": "Alpes-Maritimes",
    "7": "Ardèche",
    "8": "Ardennes",
    "9": "Ariège",
    "10": "Aube",
    "11": "Aude",
    "12": "Aveyron",
    "13": "Bouches-du-Rhône",
    "14": "Calvados",
    "15": "Cantal",
    "16": "Charente",
    "17": "Charente-Maritime",
    "18": "Cher",
    "19": "Corrèze",
    "21": "Côte-d'Or",
    "22": "Côtes-d'Armor",
    "23": "Creuse",
    "24": "Dordogne",
    "25": "Doubs",
    "26": "Drôme",
    "27": "Eure",
    "28": "Eure-et-Loir",
    "29": "Finistère",
    "30": "Gard",
    "31": "Haute-Garonne",
    "32": "Gers",
    "33": "Gironde",
    "34": "Hérault",
    "35": "Ille-et-Vilaine",
    "36": "Indre",
    "37": "Indre-et-Loire",
    "38": "Isère",
    "39": "Jura",
    "40": "Landes",
    "41": "Loir-et-Cher",
    "42": "Loire",
    "43": "Haute-Loire",
    "44": "Loire-Atlantique",
    "45": "Loiret",
    "46": "Lot",
    "47": "Lot-et-Garonne",
    "48": "Lozère",
    "49": "Maine-et-Loire",
    "50": "Manche",
    "51": "Marne",
    "52": "Haute-Marne",
    "53": "Mayenne",
    "54": "Meurthe-et-Moselle",
    "55": "Meuse",
    "56": "Morbihan",
    "57": "Moselle",
    "58": "Nièvre",
    "59": "Nord",
    "60": "Oise",
    "61": "Orne",
    "62": "Pas-de-Calais",
    "63": "Puy-de-Dôme",
    "64": "Pyrénées-Atlantiques",
    "65": "Hautes-Pyrénées",
    "66": "Pyrénées-Orientales",
    "67": "Bas-Rhin",
    "68": "Haut-Rhin",
    "69": "Rhône",
    "70": "Haute-Saône",
    "71": "Saône-et-Loire",
    "72": "Sarthe",
    "73": "Savoie",
    "74": "Haute-Savoie",
    "75": "Paris",
    "76": "Seine-Maritime",
    "77": "Seine-et-Marne",
    "78": "Yvelines",
    "79": "Deux-Sèvres",
    "80": "Somme",
    "81": "Tarn",
    "82": "Tarn-et-Garonne",
    "83": "Var",
    "84": "Vaucluse",
    "85": "Vendée",
    "86": "Vienne",
    "87": "Haute-Vienne",
    "88": "Vosges",
    "89": "Yonne",
    "90": "Territoire de Belfort",
    "91": "Essonne",
    "92": "Hauts-de-Seine",
    "93": "Seine-Saint-Denis",
    "94": "Val-de-Marne",
    "95": "Val-d'Oise",
    "971": "Guadeloupe",
    "972": "Martinique",
    "973": "Guyane",
    "974": "La Réunion",
    "975": "Saint-Pierre-et-Miquelon",
    "976": "Mayotte",
    "977": "Saint-Barthélemy",
    "978": "Saint-Martin",
    "984": "Terres australes et antarctiques françaises",
    "986": "Wallis-et-Futuna",
    "987": "Polynésie française",
    "988": "Nouvelle-Calédonie",
    "989": "Île de Clipperton"
}

export const Metrics = {
    chatbot_add_b2bmed: "Chatbot: ajout de leads B2B médical",
    copypaste_lead: "Leads: copier coller",
    lead_view_show_filters: "Leads: Afficher les filtres",
    transferHot2salesman: "Leads: transfert leads chauds à vendeur",
    transfer2salesman: "Leads: transfert à vendeur",
    reminder_add: "Rappels: ajout de rappels",
    admin_assign_leads_to_collaborator: "Manager: Assignation de leads",
    admin_unassign_leads_from_collaborator: "Manager: Désassignation de leads",
    admin_metrics_open: "Manager: Ouverture de la page des métriques",
    open_learning_page: "Formation: Ouverture de page d'apprentissage",
    "360_open": "Leads: ouverture du 360",
}

export function canUseChatbot(auth) {
    return auth?.has_access_to_chatbot === true;
}

export const Jobs = [
    "Masseur kinésithérapeute",
    "Ostéopathe",
    "Chirurgien-dentiste",
    "Pédicure-podologue",
    "Orthophoniste",
    "Orthoptiste",
    "Avocat",
];
