import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import AuthContext from "../../../context/AuthProvider";
import { date2shortFrenchString } from "../../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";


export default function GraphUsersWork({ daysUsageData }) {
    const { color } = useContext(AuthContext);

    if (!daysUsageData) {
        return null;
    }

    const maxYAxisWork = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.work_done)) * 1.2 + 1), 4);
    const maxYAxisRdv = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.rdv_taken)) * 1.2 + 1), 4);

    const getGraph = (fieldName, maxValue, title) => {
        return <Grid key={fieldName} item xs={12}>
            <h3 className="title" style={{ color }}>{title}</h3>
            <div style={{ width: '100%', height: 280 }}>
                <ResponsiveContainer>
                    <LineChart
                        data={daysUsageData.map(row => {
                            return { ...row, "Nombre de leads": row[fieldName], displayDate: date2shortFrenchString(new Date(row.day)) }
                        })}
                        margin={{ top: 30, right: 45, left: 0, bottom: 30 }}>
                        <XAxis dataKey="displayDate" angle={45} height={70} textAnchor="start" />
                        <YAxis domain={[0, maxValue]} />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Nombre de leads" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Grid>;
    }

    return (
        <>
            {getGraph("work_done", maxYAxisWork, "Leads utilisés par jour")}
            {getGraph("rdv_booked", maxYAxisRdv, "Rendez-vous pris par jour")}
        </>
    );
}
