import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import AddWorker from './component/admin/add_worker';
import Header from './component/header';
import CompanyMetrics from './component/metrics/company_metrics';
import CustomersUsage from './component/metrics/customers_usage';
import SoftwareManagement from './component/metrics/software_management';
import PopupReminders from './component/popups/popup_reminders';
import ConfirmNotification from './component/ux/confirmation';
import Toast from './component/ux/toast';
import AuthContext from "./context/AuthProvider";
import { AdminVersion, UserType } from './context/enums';
import LeadsAttribution from './leads_attribution';
import { useReminders } from './lib/reminders/useReminders';
import AttributeHotLeads from './component/admin/table/attribute_hot_leads';


const VIEWS_PROPERTIES = new Map();
VIEWS_PROPERTIES.set("leads_attribution", {
    buttonText: "Leads",
});
VIEWS_PROPERTIES.set("add_worker", {
    buttonText: "Collaborateurs",
});
VIEWS_PROPERTIES.set("hot_leads_attribution", {
    buttonText: "Leads chauds",
});

const DEFAULT_VIEWS = [...VIEWS_PROPERTIES.keys()];

VIEWS_PROPERTIES.set("companies_usage", {
    buttonText: "Clients Lead Access",
});
VIEWS_PROPERTIES.set("company_metrics", {
    buttonText: "Métriques",
});
VIEWS_PROPERTIES.set("software_management", {
    buttonText: "Logiciel",
});

export default function Admin() {
    const [viewIdx, setViewIdx] = useState(0);
    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const { auth, color, wasAuthenticatedOnce } = useContext(AuthContext);

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    const getViews = () => {
        let result = [...DEFAULT_VIEWS];

        if (!auth) {
            return result;
        }
        if (auth.email.endsWith('lead-access.fr')) {
            return [...result, "company_metrics", "companies_usage", "software_management"];
        }
        if (auth.user_type === UserType["MANAGER"]) {
            return [...result, "company_metrics"];
        }

        return result;
    }

    const isUptodateAdminVersion = (auth) => {
        if (!auth || !auth.admin_version) {
            return true;
        }

        return auth.admin_version === AdminVersion;
    }

    useEffect(() => {
        if (!wasAuthenticatedOnce) {
            return;
        }

        let isUptoDate = isUptodateAdminVersion(auth);

        if (!isUptoDate) {
            setConfirmation({
                "text": "La version de Lead Access que vous utilisez n'est plus à jour, veuillez rafraîchir la page.",
                "showYesButton": false,
                "showNoButton": false,
                "specialPopup": null,
                "confirmCallback": () => { }
            });
        }
    }, [auth, wasAuthenticatedOnce]);

    const getCentralComponent = () => {
        let viewName = getViews()[viewIdx];
        if (viewName === "leads_attribution") {
            return <LeadsAttribution setToast={setToast} setConfirmation={setConfirmation} />;
        } else if (viewName === "add_worker") {
            return <AddWorker setToast={setToast} />;
        } else if (viewName === "company_metrics") {
            return <CompanyMetrics setToast={setToast} />;
        } else if (viewName === "companies_usage") {
            return <CustomersUsage setToast={setToast} />;
        } else if (viewName === "software_management") {
            return <SoftwareManagement setToast={setToast} />;
        } else if (viewName === "hot_leads_attribution") {
            return <AttributeHotLeads setToast={setToast} setConfirmation={setConfirmation} />;
        }

        return null;
    }

    const getTabsComponents = () => {
        return getViews().map((view, idx) => {
            let buttonText = VIEWS_PROPERTIES.get(view).buttonText;
            return <Tab key={view} label={buttonText} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
        });
    }

    const getMainComponent = () => {
        if (!auth || auth.user_type !== UserType["MANAGER"]) {
            return null;
        }

        return <div className="la_column_section">
            <div className="old_body">
                <Box>
                    <Tabs
                        value={viewIdx}
                        onChange={(event, newView) => setViewIdx(newView)}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
                        aria-label="scrollable auto tabs example">
                        {getTabsComponents()}
                    </Tabs>
                </Box>
                {getCentralComponent()}
            </div>
        </div>
    }

    if (!auth || auth.user_type !== UserType["MANAGER"]) {
        return null;
    }

    return (
        <div className="la_body">
            <section className="la_section-6">
                {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
                <PopupReminders reminders={reminders}
                    reloadReminders={reloadReminders}
                    markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                    auth={auth}
                    setToast={setToast} />
                {confirmation !== null && <ConfirmNotification text={confirmation.text}
                    confirmCallback={confirmation.confirmCallback}
                    setConfirmation={setConfirmation}
                    color={color}
                    showYesButton={confirmation.showYesButton}
                    showNoButton={confirmation.showNoButton}
                    specialPopup={confirmation.specialPopup}
                    setToast={setToast} />}
                <Header />
                {getMainComponent()}
            </section>
        </div>
    );
};
