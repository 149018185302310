import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { storeFeatureUsage } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import GraphUsersWork from "./graphs/graph_users_work";
import TableUsersUsage from "./tables/table_users_usage";
import TableDaysUsage from "./tables/tables_days_usage";
import { useDaysUsage } from "./userDaysUsage";
import { useUsersUsage } from "./userUsersUsage";


export default function CompanyMetrics({ setToast }) {
    const { auth, color } = useContext(AuthContext);

    const [duration, setDuration] = useState(14);

    const [daysUsageData] = useDaysUsage({ companyId: auth.company_id, duration, job: null });
    const [usersUsageData] = useUsersUsage({ companyId: auth.company_id, duration, job: null });

    useEffect(() => {
        storeFeatureUsage(auth.id, "admin_metrics_open", null);
    }, [auth]);

    if (!auth) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>{"Métriques"}</h1>
            </Grid>

            <Grid item xs={12}>
                <InputLabel>Sélectionnez une durée:</InputLabel>
                <Select value={duration} onChange={ev => setDuration(ev.target.value)}>
                    <MenuItem value={14}>2 semaines</MenuItem>
                    <MenuItem value={1}>1 jour</MenuItem>
                    <MenuItem value={2}>2 jours</MenuItem>
                    <MenuItem value={3}>3 jours</MenuItem>
                    <MenuItem value={4}>4 jours</MenuItem>
                    <MenuItem value={5}>5 jours</MenuItem>
                    <MenuItem value={6}>6 jours</MenuItem>
                    <MenuItem value={7}>1 semaine</MenuItem>
                    <MenuItem value={21}>3 semaines</MenuItem>
                    <MenuItem value={30}>1 mois</MenuItem>
                    <MenuItem value={60}>2 mois</MenuItem>
                </Select>
                <br />
                <br />
            </Grid>

            <GraphUsersWork key="work_one" daysUsageData={daysUsageData} />

            <Grid item xs={12} key="prospecting_results_per_user">
                <h3 className="title" style={{ color }}>{`Résultats par collaborateur (${duration} jours)`}</h3>
                <br />
                <TableUsersUsage usersUsageData={usersUsageData} duration={duration} />
                <br />
            </Grid>

            <Grid item xs={12} key="prospecting_results_per_day">
                <h3 className="title" style={{ color }}>{"Résultats par jour"}</h3>
                <br />
                <TableDaysUsage daysUsageData={daysUsageData} />
                <br />
            </Grid>
        </Grid>
    );
}
