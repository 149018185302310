import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from '@fullcalendar/interaction'; // requires to drap and drop
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { useEffect, useRef, useState } from 'react';
import { getRandomValueFromArray } from './lib/tools/basics';
import { date2shortFrenchString, getTodayUTC } from './lib/tools/datetime';
import { useWindowWidth } from "./lib/ui/useWindowWidth";
import "./style/TestCalendar.css";


/*
    Compare calendar with other libraries: https://npm-compare.com/calendar,fullcalendar,react-big-calendar

    FULLCalendar:
        * full doc: https://fullcalendar.io/docs
        * custom button: https://fullcalendar.io/docs/Calendar-addEvent-demo
        * calendar navigation: https://fullcalendar.io/docs/date-navigation
        * API is often called with arguments: ?start=2025-01-1&end=2025-02-01
*/


// todo(3) clean all page its a test page


function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )
}

function renderAllDayContent() {
    return (
        <>
            Rappels
        </>
    )
}

export default function TestCalendar() {
    const windowWidth = useWindowWidth();

    const calendarRef = useRef(null);

    const [lastClickTime, setLastClickTime] = useState(0); // todo(3) later double click checker exactement la meme date
    const doubleClickThreshold = 300; // Adjust as needed

    const [events, setEvents] = useState([
        { title: 'event 1', id: 8, date: '2025-01-13T16:00:00' },
        { title: 'event 1.1', id: 9, date: '2025-01-13T16:00:00' },
        { title: 'event 1.2', id: 10, date: '2025-01-13T16:00:00' },
        { title: 'event 2', id: 11, date: '2025-01-13' }
    ]);

    useEffect(() => {
        for (const nextEv of events) {
            addEvent(nextEv);
        }
    }, [events]);

    const handleDatesSet = (data) => {
        // console.log('handleDatesSet() data :>> ', data);
        // console.log('start :>> ', data.start);
    }

    const getEventClassNames = (event) => {
        // console.log('getEventClassNames() event :>> ', event);
        return 'testmyevent';
    }

    const getRappelClassNames = (event) => {
        return 'la_first-box';
    }

    const getHeaderClassNames = (event) => {
        // console.log('getHeaderClassNames() event :>> ', event);
        return 'la_box-days text-block-57';
    }

    const getHeaderContent = (event) => {
        return { html: `<div className="text-block-57">${date2shortFrenchString(event.date)}</div>` };
    }

    const getLabelClassNames = (event) => {
        // console.log('getLabelClassNames() event :>> ', event);
        return 'la_absolut-hour';
    }

    const getLinesClassNames = (event) => {
        console.log('event.date :>> ', event.date);
        console.log('getTodayUTC() :>> ', getTodayUTC());
        const isToday = event.date === getTodayUTC(); // todo(3) doesnt work
        console.log('isToday :>> ', isToday);
        console.log('getLinesClassNames() event :>> ', event);
        // return 'cal_day_column';
        return getRandomValueFromArray(['cal_day_column', 'cal_day_column2']);
    }

    const getLinesLabelContent = (event) => {
        return "b;b;a";
    }

    const options = {
        plugins: [interactionPlugin, timeGridPlugin],
        initialView: windowWidth > 1000 ? 'timeGridWeek' : 'timeGridFourDay',
        headerToolbar: {
            left: 'prev,today,next',
            center: 'title',
            right: 'timeGridDay,timeGridFourDay,timeGridWeek'
        },
        views: {
            timeGridFourDay: {
                type: 'timeGrid',
                duration: { days: 4 }, // a date-range parameter is required, whether it be duration, visibleRange, or dayCount.
                buttonText: '4 jours',
                slotDuration: '00:15:00', // todo(1) add to timeGridWeek, ca se met dans les options a la base..
                slotMinTime: "08:00:00",
                slotMaxTime: "22:00:00",
                nowIndicator: true,
            }
        },
        eventMaxStack: 2,  // todo(3) mettre a 4 par jour

        // eventContent: renderEventContent,
        allDayContent: renderAllDayContent,
        eventClassNames: getEventClassNames,
        allDayClassNames: getRappelClassNames, // left part, rappels
        // viewClassNames: getEventClassNames, // full view
        // nowIndicatorClassNames: getEventClassNames, // nothing
        // weekNumberClassNames: getEventClassNames, // nothing
        dayHeaderClassNames: getHeaderClassNames, // top line
        dayHeaderContent: getHeaderContent,
        slotLabelClassNames: getLabelClassNames, // left part
        // slotLaneClassNames: getLinesClassNames, // middle
        // moreLinkClassNames: getEventClassNames, // more


        // initialDate: '2017-02-01',
        // header: {
        //     left: 'prev,next',
        //     center: 'title',
        //     right: 'timeGridWeek'
        // },
        expandRows: true,
        weekends: true,//windowWidth > 1000,
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        selectable: true,
        dayMaxEvents: true,
        datesSet: handleDatesSet,
        // height: "100%",

        // timeZone: 'local', todo(3) ajouter
        // dateSet: evenet trigger when calendar is loaded
        locale: frLocale // Set locale to French
        // visibleRange: function (currentDate) {
        //     console.log('visibleRange() currentDate :>> ', currentDate);
        //     // Generate a new date for manipulating in the next step
        //     var startDate = new Date(currentDate.valueOf());
        //     var endDate = new Date(currentDate.valueOf());

        //     // Adjust the start & end dates, respectively
        //     startDate.setDate(startDate.getDate() - 1); // One day in the past
        //     endDate.setDate(endDate.getDate() + 2); // Two days into the future

        //     return { start: startDate, end: endDate };
        // }
    };

    const addEvent = (event) => {
        if (!calendarRef?.current) return;
        const calendarApi = calendarRef.current.getApi();
        // calendarApi.next();
        const fcEvent = calendarApi.addEvent(event);
        console.log('fcEvent :>> ', fcEvent);
        console.log('fcEvent.id :>> ', fcEvent.id);
        console.log('fcEvent.extendedProps :>> ', fcEvent.extendedProps);
    }

    const removeEvent = (novaId) => {// todo(3) rename otus les novaId il y en a plus, que des id
        if (!calendarRef?.current) return;
        const calendarApi = calendarRef.current.getApi();

        const event = calendarApi.getEventById(novaId);
        if (event) {
            event.remove();
        }
    }

    const handleEventClick = (eventInfo) => {
        console.log('eventInfo :>> ', eventInfo);
        console.log('eventInfo.event.title :>> ', eventInfo.event.title);
    }

    const handleDateClick = (info) => {
        console.log('info.view.type :>> ', info.view.type); // todo(3) autoriser que certaines views
        console.log('handleDateClick() info :>> ', info); // allDay todo(3) gerer quand true
        const currentTime = Date.now();
        const diffInMs = currentTime - lastClickTime;
        console.log('diffInMs :>> ', diffInMs);

        if (diffInMs < doubleClickThreshold) {
            console.log('Double-click on time slot:', info.dateStr);
        }

        setLastClickTime(currentTime);
    }

    const handleEventChange = (status) => {
        console.log('status :>> ', status);
        const { event, oldEvent, relatedEvents } = status;
        console.log('event.title :>> ', event.title);
        console.log('event.id :>> ', event.id);
        console.log('event.start :>> ', event.start);
        console.log('event.end :>> ', event.end);
        console.log('event.endStr :>> ', event.endStr);
        console.log('event.allDay :>> ', event.allDay);
        console.log('event.classNames :>> ', event.classNames);
        console.log('event.extendedProps :>> ', event.extendedProps); // here appear others
    }

    return (
        <div >
            {/* <div className='la_holder-calendar'> */}
            <button onClick={() => removeEvent(8)}>GetEvent</button>
            <FullCalendar
                // events={events} 
                ref={calendarRef}
                {...options}
                eventAdd={() => {/* ic on appelle api quand element added */ }}
                eventClick={handleEventClick}
                nowIndicator={true}
                selectable={false}
                editable={true}
                eventChange={handleEventChange}
                dateClick={handleDateClick}
            // dayCellContent
            // nextDayThreshold={() => { return { days: 3 } }}
            />
        </div>
    );
}


{/* <FullCalendar
  plugins={[ dayGridPlugin ]}
  initialView="dayGridMonth"
  weekends={false}
  events={[
    { title: 'event 1', date: '2019-04-01' },
    { title: 'event 2', date: '2019-04-02' }
  ]}
    dateClick={handleDateClick}
/> */}

//
//  import React, { useState } from 'react'
// import { formatDate } from '@fullcalendar/core'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import interactionPlugin from '@fullcalendar/interaction'
// import { INITIAL_EVENTS, createEventId } from './event-utils'

// export default function DemoApp() {
//   const [weekendsVisible, setWeekendsVisible] = useState(true)
//   const [currentEvents, setCurrentEvents] = useState([])

//   function handleWeekendsToggle() {
//     setWeekendsVisible(!weekendsVisible)
//   }

//   function handleDateSelect(selectInfo) {
//     let title = prompt('Please enter a new title for your event')
//     let calendarApi = selectInfo.view.calendar

//     calendarApi.unselect() // clear date selection

//     if (title) {
//       calendarApi.addEvent({
//         id: createEventId(),
//         title,
//         start: selectInfo.startStr,
//         end: selectInfo.endStr,
//         allDay: selectInfo.allDay
//       })
//     }
//   }

//   function handleEventClick(clickInfo) {
//     if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
//       clickInfo.event.remove()
//     }
//   }

//   function handleEvents(events) {
//     setCurrentEvents(events)
//   }

//   return (
//     <div className='demo-app'>
//       <Sidebar
//         weekendsVisible={weekendsVisible}
//         handleWeekendsToggle={handleWeekendsToggle}
//         currentEvents={currentEvents}
//       />
//       <div className='demo-app-main'>
//         <FullCalendar
//           plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//           headerToolbar={{
//             left: 'prev,next today',
//             center: 'title',
//             right: 'dayGridMonth,timeGridWeek,timeGridDay'
//           }}
//           initialView='dayGridMonth'
//           editable={true}
//           selectable={true}
//           selectMirror={true}
//           dayMaxEvents={true}
//           weekends={weekendsVisible}
//           initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
//           select={handleDateSelect}
//           eventContent={renderEventContent} // custom render function
//           eventClick={handleEventClick}
//           eventsSet={handleEvents} // called after events are initialized/added/changed/removed
//           /* you can update a remote database when these fire:
//           eventAdd={function(){}}
//           eventChange={function(){}}
//           eventRemove={function(){}}
//           */
//         />
//       </div>
//     </div>
//   )
// }

// function renderEventContent(eventInfo) {
//   return (
//     <>
//       <b>{eventInfo.timeText}</b>
//       <i>{eventInfo.event.title}</i>
//     </>
//   )
// }

// function Sidebar({ weekendsVisible, handleWeekendsToggle, currentEvents }) {
//   return (
//     <div className='demo-app-sidebar'>
//       <div className='demo-app-sidebar-section'>
//         <h2>Instructions</h2>
//         <ul>
//           <li>Select dates and you will be prompted to create a new event</li>
//           <li>Drag, drop, and resize events</li>
//           <li>Click an event to delete it</li>
//         </ul>
//       </div>
//       <div className='demo-app-sidebar-section'>
//         <label>
//           <input
//             type='checkbox'
//             checked={weekendsVisible}
//             onChange={handleWeekendsToggle}
//           ></input>
//           toggle weekends
//         </label>
//       </div>
//       <div className='demo-app-sidebar-section'>
//         <h2>All Events ({currentEvents.length})</h2>
//         <ul>
//           {currentEvents.map((event) => (
//             <SidebarEvent key={event.id} event={event} />
//           ))}
//         </ul>
//       </div>
//     </div>
//   )
// }

// function SidebarEvent({ event }) {
//   return (
//     <li key={event.id}>
//       <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
//       <i>{event.title}</i>
//     </li>
//   )
// }
