import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getFeatureUsage } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { Metrics } from "../../../context/enums";
import { date2shortFrenchString } from "../../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";


export default function GraphUsageMetrics({ metric, companyId, duration }) {
    const { color } = useContext(AuthContext);

    const [metricsValues, setMetricsValues] = useState([]);

    useEffect(() => {
        if (companyId === "__undefined__") {
            return;
        }

        getFeatureUsage(metric, companyId, duration).then(data => {
            console.log('getFeatureUsage() result :>> ', data.data);
            setMetricsValues(data.data.map(row => { return { day: row.day, "usage_count": parseInt(row.usage_count) } }));
        });
    }, [metric, companyId, duration]);

    // todo(1) fonction en double
    const maxYAxis = findNextMultiple(Math.round(getArrayMaxValue(metricsValues.map(row => row.usage_count)) * 1.2 + 1), 4);

    return <Grid item xs={12}>
        <h3 className="title" style={{ color }}>{Metrics[metric]}</h3>
        <div style={{ width: '100%', height: 280 }} key={`graph_${metric}_${companyId}`}>
            <ResponsiveContainer>
                <LineChart
                    data={metricsValues.map(row => {
                        return { "Usage": row.usage_count, displayDate: date2shortFrenchString(new Date(row.day)) }
                    })}
                    margin={{ top: 30, right: 45, left: 0, bottom: 30 }}>
                    <XAxis dataKey="displayDate" angle={45} height={70} textAnchor="start" />
                    <YAxis domain={[0, maxYAxis]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Usage" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    </Grid>
}
