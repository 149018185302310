const requestPermissionIfNeedBe = async (successCallBack) => {
    if (!('Notification' in window)) {
        return;
    }

    if (Notification.permission !== 'granted') {
        console.log("request noficiations permission");
        Notification.requestPermission().then(newPermission => {
            if (newPermission === 'granted') {
                successCallBack();
            }
        });
    }
}

const addNotification = (title, validateText) => {
    const notification = new Notification(title, {
        body: validateText,
        // icon: '/path/to/your/icon.png' // Optional
    });

    notification.onclick = () => {
        console.log('Notification clicked!');
    };
};

const getNotificationPermission = () => {
    // returns [isSupported: bool, value: string]

    // First check if the Notification API exists
    if (!('Notification' in window)) {
        return [false, 'unsupported'];
    }

    // For iOS Safari and older browsers that don't support Notification.permission
    if (typeof Notification.permission === 'undefined') {
        return [true, 'denied'];
    }

    return [true, Notification.permission]
};


module.exports = {
    requestPermissionIfNeedBe,
    addNotification,
    getNotificationPermission,
};
